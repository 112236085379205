<template>
    <div>
        <div v-if="isSmallScreen" class="pchome">
            <header>
                <img class="imgTop" src="@/assets/topbg.png" />
            </header>
            <div class="dao">
              <span id="shouye" @click="goHome">首页 ></span>
              <span id="myElement"> 正文 </span>
            </div>
            <!-- <div class="iconimg" style="position: relative;">
                <img src="@/assets/bannar.png" class="imgs" />
                <video style="width: 50vw;margin: 0 auto;" src="@/assets/乡村振兴会客厅专题栏目宣传片.mp4" controls></video>
                <div class="yiny">新华社客户端乡村振兴会客厅专题栏目简介</div>
            </div>
            <h2 style="text-align: center;margin-top: 25px;">新华社客户端乡村振兴会客厅专题栏目简介</h2> -->
            <div class="zheng" v-html="advertisementDetail">
                
            </div>
            <!-- <p class="title">背景介绍：</p>
                强国必先强农，农强方能国强。2024年是中华人民共和国成立75周年，是实现“十四五”规划目标任务的关键一年。2月3日，2024中央一号文件发布，提出有力有效推进乡村全面振兴“路线图”。推进中国式现代化，必须坚持不懈夯实农业基础，推进乡村全面振兴。把推进乡村全面振兴作为新时代新征程“三农”工作的总抓手，坚持以人民为中心的发展思想，完整、准确、全面贯彻新发展理念，因地制宜、分类施策，循序渐进、久久为功，集中力量抓好办成一批群众可感可及的实事，不断取得实质性进展、阶段性成果。走共同富裕的乡村振兴道路，推进乡村振兴，让老百姓过上好日子，习近平总书记惦念在心。同心筑梦，凝聚奋进力量，激发强大合力。
                新华社客户端社会频道于2024年3月起开辟的乡村振兴会客厅专题栏目，旨在聚焦城乡发展热点，描绘产业升级蓝图，搭建信息服务平台，助力乡村高质量发展。该专题由振兴领航、助农兴农、在一线、《乡村振兴会客厅》等板块内容组成，以大型演播室高端访谈栏目《乡村振兴会客厅》为核心，极力打造国家级乡村振兴第一信息发布平台。同时，乡村振兴优秀案例征集活动、消费帮扶助力乡村振兴专属电商平台薪福荟选同步启动。
                <p class="title">专题各板块聚焦方向：</p>
                <p class="title">1、振兴领航</p>
                集中展示在乡村振兴事业中做出突出成绩、发展模式创新、引领时代风气、社会广泛赞誉的政府部门、企事业单位、组织机构及先进个人。讲述他们的故事，讴歌他们的精神，为乡村振兴新时代发展提供典型范本和交流平台。
                发布形式：图文、视频、专访、专题报道。
                <p class="title">2、助农兴农</p>
                聚焦企事业单位、科研院所及社会各级组织，围绕他们在实践乡村振兴的伟大进程中，在助农兴农领域做出突出贡献及取得丰硕成果。尤其是在政策落实、专家指导、产业导入、资金支持及资源配备方面的先进帮扶榜样，成为事迹展示、荣誉表彰、经验交流和精神引领的集成窗口。
                发布形式：图文、视频、专访、专题报道。
                <p class="title">3、在一线</p>
                突出奋斗在乡村振兴事业一线的先进基层组织和个人，讲述他们的奋斗故事，汇聚他们的实践能量，讴歌人才引领聚力，展现在乡村振兴进程中第一时间、第一现场的鲜活故事。  
                发布形式：图文、视频、专访、专题报道。
                <p class="title">4、《乡村振兴会客厅》</p>
                以乡村振兴为核心内容的高端访谈栏目品牌。通过演播室访谈、短片介绍、实时连线及现场互动等方式，打造权威政策解读、乡村热点透视、产业蓝图描绘、榜样力量弘扬的权威载体和时代窗口。
                发布形式：视频20-25分钟/期，30期/年。 -->
            <div class="fuj" v-if="fileList.length > 0">附件</div>
            <div style="padding: 0 10%;padding-bottom: 100px;" v-if="fileList.length > 0">
                    <div class="box" v-for="item in fileList" :key="item.id">
                        <img src="@/assets/img/Mask group.png" />
                            <div class="bostitle">{{ item.name }}</div>
                                <span style="display: inline-block;width: 250px;">
                                  {{ (item.size / 1024 / 1024).toFixed(2) > 1 ? (item.size / 1024 / 1024).toFixed(2) + 'MB' :  + (item.size / 1024).toFixed(2) + 'KB'  }}
                                </span>
                                <span style="cursor: pointer;" @click="downloadFile(item)">下载</span>
                    </div>
            </div>
            <div class="bottombe">
                新华薪福：京ICP备2024057082号-1
            </div>
        </div>
        <div v-else class="mobilehome">
            <img class="top" src="@/assets/img/top.png">
            <div class="mobile_dao">
              <span id="shouye" @click="goHome">首页 ></span>
              <span>正文</span>
            </div>
            <div class="main" v-html="advertisementDetail">
            
            </div>
            <!-- <div style="position: relative;">
                    <img class="sdlider-img" src="https://images.xinfuhuixuan.com/images/xinhuaxinfu/4b23486cf4954a5b98dff14a2e394b4b.jpg">
                    <div class="slider-div slider-tle">
                        征集乡村振兴典型，共绘美好未来新篇章
            </div>
                </div>
                <div class="hkt-text" >
                    振兴领航，是新时代赋予我们的重要使命。乡村振兴不仅是经济社会的全面发展，更是国家长治久安和民族复兴的基石。因此，我们必须坚定信心，迎难而上，以领航者的姿态，引领乡村振兴的航程。 首先，振兴领航需要我们有清晰的战略规划和目标导向。我们要深入调研，了解乡村发展的实际需求和潜力，制定切实可行的振兴计划。同时，我们要紧紧围绕乡村振兴战略的总要求，以产业兴旺、生态宜居、乡风文明、治理有效、生活富裕为目标，全面推进乡村振兴。
                </div>
                <div class="hkt-text">
                      首先，一位农业专家为大家详细介绍了当前农业发展的新形势和新机遇。他指出，随着科技的不断进步和政策的持续支持，农业产业正迎来前所未有的发展机遇。同时，他也提醒大家要关注农业生产中的生态环境保护和可持续发展问题。
                </div> -->
            <div class="fj" v-if="fileList.length > 0">
                    <div class="fj-top" v-if="fileList.length > 0">
                        <img style="width: 1.8vw;height: 3.5vw;margin-right: 1.2vw;" src="@/assets/img/Polygon.png">
                        <div style="width: 12vw;height: 3.8vw;line-height: 3.8vw;font-size: 0.85rem;">附件</div>
                        <hr style="background-color: #BDC0C3;height: 1px;width: 80vw;">
                    </div> 
                    <div class="fj-bottom">
                        <div class="fj-bottom-item" v-for="item in fileList" :key="item.id">
                            <img style="margin-right: 2vw;height: 6vw;width: 6vw;" src="@/assets/img/Mask group.png" >
                            <div style="width: 57vw;font-size: 0.7rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                              {{ item.name }}
                            </div>
                            <div style="display: flex;">
                                <div  class="fj-bottom-text" style="width: 15vw;">
                                  {{ (item.size / 1024 / 1024).toFixed(2) > 1 ? (item.size / 1024 / 1024).toFixed(2) + 'MB' :  + (item.size / 1024).toFixed(2) + 'KB'  }}
                                </div>
                                <div id="copy_btn" class="fj-bottom-text" style="width: 10vw;color: #0F6ABE;" @click="downloadFile(item)">下载</div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="footer" v-if="toTop" @click="goTop">
                <div style="margin-left: 82vw;" id="goTop">
                    <img style="height: 13vw;width: 13vw;" src="@/assets/img/Group 2814.png">
                </div>
            </div>
            <div class="bah">
                新华薪福：京ICP备2023028182号-1
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'

  export default{
    data(){
      return{
        // 决定显示pc端还是mobile端
        isSmallScreen: true,
        title: '',
        advertisementDetail:'',
        fileList: [], // 附件信息
        autoPlayInterval: null,
        toTop: false,
      }
    },
    async created(){
        const data = await axios.get('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/theme/getArticleById?articleId=' + this.$route.query.articleId)
        if(data.data.code === 200){
            // console.log(data.data.extend.data,data.data.extend.data.list.length)
            if(data.data.extend.data.list.length > 0){
              this.fileList = data.data.extend.data.list
            }
            this.articleDetail = data.data.extend.data.content.replace(/<span style="/gi, match => {
                // 使用正则表达式匹配 <span> 标签的开头，并添加 style 属性
                // 注意：这种方法可能不总是安全的，因为它假设了 HTML 的格式  
                return match.replace(/<span style="/, '<span style="word-break: break-all;')
            })
            this.advertisementDetail = this.articleDetail.replace(/<video/gi, match => {  
                // 使用正则表达式匹配 <span> 标签的开头，并添加 style 属性  
                // 注意：这种方法可能不总是安全的，因为它假设了 HTML 的格式  
                return match.replace(/<video/, '<video style="width: 100% !important;" autoplay')
            })
            // this.intro = data.data.extend.data.intro
            // this.createTime = data.data.extend.data.createTime
            // this.source = data.data.extend.data.source
            // this.themeId = data.data.extend.data.themeId
        }
    },
    mounted() {
      // 决定显示哪个元素
      this.screenWidth()
      window.addEventListener('resize', this.innerWidth)
      // 控制滑动顶部按钮是否显示
      window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods:{
      // 物理宽度
      screenWidth(){
        const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
        if(isInWeChatMiniProgram){
          this.isSmallScreen = false
          return
        }
        let width = screen.width
        if (width >= 550) {
          this.isSmallScreen = true
        } else {
          this.isSmallScreen = false
        }
      },
      // 窗口宽度
      innerWidth(){
        const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
        if(isInWeChatMiniProgram){
          this.isSmallScreen = false
          return
        }
        let width = window.innerWidth
        if (width >= 550) {
          this.isSmallScreen = true
        } else {
          this.isSmallScreen = false
        }
      },
      // 跳转广告页
      advertisement(id){
        // this.$router.push({
        //   path: '/advertisement',
        // })
      },
      goHome(){
        this.$router.push({path: '/home'})
      },
      // 下载文件
      downloadFile(file){
        const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
        if(isInWeChatMiniProgram){
          // 初始化 clipboard.js 实例
          this.clipboard = new ClipboardJS('#copy_btn', {
            text: () => {
              // 返回要复制的文本
              return file.url
            },
          })
          // 添加成功和失败的回调
          this.clipboard.on('success', (e) => {
            // console.log('文本已复制到剪贴板')
            // 清除选中文本
            e.clearSelection()
          })
          this.clipboard.on('error', (e) => {
            // console.error('复制失败')
          })
          this.$message.success('文件地址已复制到剪贴板，请到浏览器下载')
        }else{
          // 创建一个临时的a标签  
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = file.url  
          // 设置下载的文件名，这里假设URL已经包含了文件名，或者你可以手动设置
          a.download = file.name // 你可以根据需要修改文件名  
          // 将a标签添加到文档中  
          document.body.appendChild(a)
          // 触发点击  
          a.click()
          // 清理：移除a标签并释放URL对象  
          document.body.removeChild(a)
        }
      },
      // 控制返回顶部按钮显示/隐藏
      handleScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const screenHeight = window.innerHeight || document.documentElement.clientHeight
        if (scrollTop * 5 > screenHeight) {  
          this.toTop = true
        } else {  
          this.toTop = false
        }  
      },
      // 滚动到顶部
      goTop(){
        window.scrollTo({  
          top: 0,  
          behavior: 'smooth'  
        })
      },
    }
  }
  </script>
  
  <style lang="less" scoped>
    // pc样式
    body, html {margin: 0;padding: 0;font-family: Arial, sans-serif;font-size:24px;}
	*{padding: 0;margin: 0;}
	header {height: 11vw;}
	header img{width: 100%; height: 100%;}
  .dao{font-size: 18px;color:#666; cursor: pointer;line-height: 80px;padding-left: 13%;text-align: left;}
	.iconimg{height: 45vw; margin-top: 20px;}
	.imgs{width: 60vw;height: 15vw;}
	.zheng{padding:15px 10%;white-space:pre-wrap;font-size: 20px;color: #666666;text-align: left;margin-bottom: 70px;}
	.fuj{margin: 0 70px; background-color: #f3f3f3;font-size: 24px;padding: 15px 5%; box-sizing: border-box;text-align: left;}
	.box{display: flex;align-items: center;margin-top: 20px;font-size: 22px;}
	.box img{width: 26px;padding-right: 20px;}
	.boxleft div{font-size: 16px;display: flex;}
	.bostitle{width: 50%;}
	span{cursor: pointer;width: 10%;}
	.bottombe{height: 70px;background-color: #005099;font-size: 18px;color: #FFFFFF;line-height: 70px;text-align: center;position: fixed;bottom: 0;width: 100%;}
	.yiny{position: absolute;bottom: 30vw;left: 19.6vw;width: 60vw;height: 70px;line-height: 70px; font-size: 24px;color: #FFFFFF;background-color: #05336A;opacity: 0.7;}
	.title{color: black;font-size: 24px;font-weight: bold;text-align: left;}

    // mobile样式
    .top{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 70px;
        z-index: 9;
    }
    .main{
        margin: 0 auto 18vw;
        width: 95vw;
    }
    .main{
      ::v-deep .articleDetail{
            color: yellow !important;
        }
      ::v-deep p{
        img{
          width: 100%;
        }
      }
      ::v-deep div{
        background-color: yellow !important;
        div{
            background-color: yellow;
        //   video{
        //     width: 100%;
        //   }
        }  
      }
    }
    .mobile_dao{
      margin: 70px auto -10px;
      width: 95vw;
      height: 40px;
      line-height: 40px;
      text-align: left;
      color: #666666;
      font-size: 0.8rem;
    }
    .ad{
        width: 95vw;
        height: 60vw;
    }
    .sdlider-img {
        width: 100%;
        height: 45vw;
        border-radius: 5px;
    }
    .slider-div {
        box-sizing: border-box;
    }
    .slider-tle{
        padding-left: 3vw;
        position: absolute;
        top: 33vw;
        width: 100%;
        height: 12vw;
        line-height: 12vw;
        background: url('@/assets/img//Rectangle\ 1154.png');
        border-bottom-left-radius: 5px;  
        border-bottom-right-radius: 5px;
        font-size: 0.85rem;
        color: #FFFFFF;
    }
    .hkt-text{
        margin-top: 4vw;
        text-indent:1.5rem;
        font-size: 0.75rem;
        color: #666666;
    }
    .fj{
          margin: 0 auto;
          width: 95vw;
        }
    .fj-top{
        display: flex;
        margin:10vw 0 4vw;
        align-items: center;
        width: 100%;
    }
    .fj-bottom{
        height: 40vw;
    }
    .fj-bottom-item{
        display: flex;
        align-items: center;
        height: 7.5vw;
    }
    .fj-bottom-text{
        text-align: right;
        font-size: 0.75rem;
    }

    .footer{
        position: fixed;
        bottom: 20vw;
    }
    /* 备案号 */
    .bah{
        position: fixed;
        bottom: 0;
        width: 100vw;
        height: 15vw;
        line-height: 15vw;
        text-align: center;
        background-color: #005099;
        font-size: 0.8rem;
        color: #FFFFFF;
    }
  </style>
  