import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Article from '../views/article/index.vue'
import ArticleDetail from '../views/articleDetail/index.vue'
import Advertisement from '../views/advertisement/index.vue'
// import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
  {
    // 重定向
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: About
  },
  {
    // 文章列表页
    path: '/article',
    name: 'article',
    component: Article
  },
  {
    // 文章详情页
    path: '/articleDetail',
    name: 'articleDetail',
    component: ArticleDetail
  },
  {
    // 顶部广告页
    path: '/advertisement',
    name: 'advertisement',
    component: Advertisement
  }
]

const router = new VueRouter({
  mode: 'hash', // hash模式防止页面刷新404
  routes,
  ignoreDuplicates: true, // 忽略重复导航错误
})

// // 全局前置守卫
// router.beforeEach((to, from, next) => {
//   // 检查目标路由和当前路由是否相同
//   if (to.path === from.path) {
//     // 如果相同，调用 next(false) 来阻止导航
//     next(false);
//   } else {
//     // 否则，允许导航
//     next();
//   }
// });

export default router
