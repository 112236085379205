<template>
  <div>
    <div v-if="isSmallScreen" class="pchome">
      <header>
        <img class="imgTop" src="@/assets/topbg.png" @click="advertisement"/>
      </header>
      <nav class="navbar">
        <div class="a_title" v-for="(item,index) in navbarList" :key="item.id">
          <a :ref="'link' + (index + 1)" @click="scrollToSection('themeId' + item.id, 'pc', item.id,'click')">{{ item.themeName }}</a>
          <!-- 获取动态数据后，先将第一项的visible设置为true，别的都设置为false,之后点击那个设置为true，别的设置为false-->
          <div class="line" :style="{ display: item.visible ? 'block' : 'none' }"></div>
        </div>
      </nav>
      <div class="main-content">
        <div style="display: flex;">
          <div style="width: 100%;">
            <section :id="'themeId' + homeItems.themeId" class="section" style="margin-bottom: 0;width: 100%;" v-for="(homeItems,homeIndex) in homeList.filter(item => item.themeType === 2)" :key="homeItems.themeId">
              <div class="section-content rightbor">
              <div class="section-header">
                <h1 class="section-title">
                <div class="shut"></div>
                {{ homeItems.themeName }}
              </h1>
                <a class="more-link" @click="seeMore(homeItems.themeId,homeItems.themeName)">查看更多</a>
              </div>
                <p>
                  <ul class="conttitle">
                    <li v-for="homeItem in homeItems.data" :key="homeItem.articleId" @click="goDetail(homeItem.articleId,homeItem.title)"><div class="right">{{ homeItem.title }}</div></li>
                  </ul>
                </p>
              </div>
              <!-- 轮播图 -->
              <!-- <div class="section-image">
                <div class="carousel">
                  <div ref="carousel__slide" class="carousel__slider">
                    <div class="carousel__slides" :style="{ transform: `translateX(-${slideWidth * currentIndex[homeIndex]}px)` }">
                      <div v-for="(item, index) in carouselList[homeIndex].imgList" :key="index" class="carousel__slide">
                        <img :src="item.image"  @click="goDetail(item.id)"/>
                      </div>
                    </div>
                  </div>
                  <div class="carousel__control carousel__control--prev" @click="prevSlide(homeIndex)"></div>
                  <div class="carousel__control carousel__control--next" @click="nextSlide(homeIndex)"></div>
                  <div class="carousel__indicatorsPc">
                    <span v-for="(item, index) in carouselList[homeIndex].imgList" :key="index" :class="{ active: index === currentIndex[homeIndex] }" class="carousel__indicator" @click="goToSlide(homeIndex)"></span>
                  </div>
                  <div class="yinzi">让农村更美丽、农业更兴旺、农民更幸福(移动)</div>
                </div>
              </div> -->
            </section>
          </div>
          <div style="width: 100%;" v-if="carouselList.length !== 0">
            <div class="section-image" style="height: 50%;">
              <div class="carousel">
                <div ref="carousel__slide" class="carousel__slider">
                  <div class="carousel__slides" :style="{ transform: `translateX(-${slideWidth * currentIndex1}px)` }">
                    <div v-for="(item, index) in carouselList[0].imgList" :key="index" @click="goDetail(item.id)" class="carousel__slide">
                      <img :src="item.image" />
                      <div class="yinzi">{{ item.title }}</div>
                    </div>
                  </div>
                </div>
                <div class="carousel__control carousel__control--prev" @click="prevSlide(0)"></div>
                <div class="carousel__control carousel__control--next" @click="nextSlide(0)"></div>
                <div class="carousel__indicatorsPc">
                  <span v-for="(item, index) in carouselList[0].imgList" :key="index" :class="{ active: index === currentIndex1 }" class="carousel__indicator" @click="goToSlide(index)"></span>
                </div>
              </div>
            </div>
            <div class="section-image" style="height: 50%;">
              <div class="carousel">
                <div ref="carousel__slide" class="carousel__slider">
                  <div class="carousel__slides" :style="{ transform: `translateX(-${slideWidth * currentIndex2}px)` }">
                    <div v-for="(item, index) in carouselList[1].imgList" @click="goDetail(item.id)" :key="index" class="carousel__slide">
                      <img :src="item.image" />
                      <div class="yinzi">{{ item.title }}</div>
                    </div>
                  </div>
                </div>
                <div class="carousel__control carousel__control--prev" @click="prevSlide(1)"></div>
                <div class="carousel__control carousel__control--next" @click="nextSlide(1)"></div>
                <div class="carousel__indicatorsPc">
                  <span v-for="(item, index) in carouselList[1].imgList" :key="index" :class="{ active: index === currentIndex2 }" class="carousel__indicator" @click="goToSlide(index)"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        
        <section :id="'themeId' + homeItems.themeId" class="section" v-for="homeItems in homeList.filter(item => item.themeType === 3)" :key="homeItems.themeId">
          <div class="section-header" style="width:100vw;">
                <h1 class="section-title"><div class="shut"></div>{{ homeItems.themeName }}</h1>
                <a class="more-link" @click="seeMore(homeItems.themeId,homeItems.themeName)">查看更多 </a>
          </div>
          <div style="width:100vw;height:160px;display: flex;margin-top: 20px;" v-for="item in homeItems.data" :key="item.articleId" @click="goDetail(item.articleId,item.title)">
            <div style="width:256px;height:144px;display:flex;justify-content:center;align-items:center;margin-right: 20px;" v-if="item.img">
              <img style="width:256px;height:144px;" :src="item.img"/>
            </div>
            <div style="padding-top: 20px;border-bottom: 2px solid #BDC0C3;height: 124px;width: 100%;">
              <div class="wentitle">{{ item.title }}</div>
              <div class="miaoshu">{{ item.intro }}</div>
              <div style="font-size: 18px;color: #999;text-align: right;" v-if="item.createTime">{{ item.createTime }}</div>
            </div>
          </div>
        </section>
        <section :id="'themeId' + homeItems.themeId" class="section" v-for="homeItems in homeList.filter(item => item.themeType === 4)" :key="homeItems.themeId">
          <div class="section-header" style="width:100vw;">
            <h1 class="section-title"><div class="shut"></div>{{ homeItems.themeName }}</h1>
          </div>
          <div style="font-size: 20px;text-indent: 2em;color: #666666;margin-top: 20px;text-align: left;" v-html="homeItems.data"></div> 
        </section>
      </div>
      <div class="bottombe">
        新华薪福：京ICP备2024057082号-1
      </div>
      <img src="@/assets/ding.png" width="70px" height="70px" id="to-top" v-if="toTop" @click="goTop" style="position:fixed;bottom:150px;right:50px;cursor: pointer;" />
    </div>
    <div v-else class="mobilehome">
      <img class="top" src="@/assets/img/top.png" @click="advertisement">
      <div class="mobileNavbar" id="Navbar" ref="mobileUl">
          <ul id="ul">
              <!-- 获取动态数据后，先将第一项的visible设置为true，别的都设置为false,之后点击那个设置为true，别的设置为false-->
              <li v-for="(item,index) in navbarList" :key="item.id">
                <!-- <a :ref="'link' + (index + 1)" :id="'link' + (index + 1)"  @click="scrollToSection('themeId' + item.id, 'mobile', item.id,item)" :class="{'active': item.id == activeItemId}"> -->
                <a :ref="'link' + (index + 1)" :id="'link' + (index + 1)"  @click="scrollToSection('themeId' + item.id, 'mobile', item.id,item, 'click')" :class="{'active': item.visible}">
                  {{ item.themeName }}</a></li>
          </ul>
      </div>
      
      <div class="main">
            <!-- 第一个轮播图 -->
            <div v-for="(homeItems) in homeList.filter((item,index) => item.themeType === 2 && index === 0)" :key="homeItems.themeId">
              <div class="first" :id="'themeId' + homeItems.themeId" :ref="'themeId' + homeItems.themeId">
                <div class="piece"></div>
                <div class="zxyw">{{ homeItems.themeName }}</div>
                <div class="ckgd goPage" @click="seeMore(homeItems.themeId,homeItems.themeName)">查看更多</div>
              </div>
              <div class="second">
                  <div v-for="homeItem in homeItems.data" :key="homeItem.articleId" @click="goDetail(homeItem.articleId,homeItem.title)">
                      <div class="spot"></div>
                      <div style="font-size: 0.8rem;overflow: hidden; white-space: nowrap;text-overflow: ellipsis">{{ homeItem.title }}</div>
                  </div>
              </div>
              <!-- <div class="section-image">
                <div class="carousel" style="border-radius: 10px 10px;heightsliderNext: 45vw;">
                  <div ref="mobilecarousel__slide" class="carousel__slider">
                    <div class="carousel__slides" :style="{ transform: `translateX(-${slideWidth * currentIndex1}px)` }">
                      <div v-for="(item, index) in carouselList[0].imgList" :key="index" class="carousel__slide" style="height: 45vw;">
                        <img :src="item.image" @click="goDetail(item.id)"/>
                        <div class="yinziMobile">{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel__control carousel__control--prev" @click="prevSlide(0)"></div>
                  <div class="carousel__control carousel__control--next" @click="nextSlide(0)"></div>
                  <div class="carousel__indicators" style="position: absolute;left: 60px;bottom: 11vw;transform: translateX(-50%);-webkit-transform: translateX(-50%);-moz-transform: translateX(-50%);">
                    <span v-for="(item, index) in carouselList[0].imgList" :key="index" :class="{ indicatorsActive: index === currentIndex1 }" class="carousel__indicator" @click="goToSlide(index)"></span>
                  </div>
                </div>
              </div> -->
              <!-- <div style=""> -->
                  <slider ref="slider" :options="options">
                    <slideritem v-for="(item,index) in carouselList[0].imgList" :key="index">
                      <!-- {{item.title}} -->
                      <div style="width:100%;position: relative;height: 50vw;">
                        <img :src="item.image" style="width: 100%;border-radius: 20px;height: 100%;" @click="goDetail(item.id)"/>
                        <div class="carousel__control carousel__control--prev" @click="sliderPrev(0)"></div>
                        <div class="carousel__control carousel__control--next" @click="sliderNext(0)"></div>
                        <div style="position: absolute;bottom: 0;left: 0;width: 100%;height: 45px;line-height: 45px;
                          background-color: rgba(0, 0, 0, 0.4);border-radius:0 0 20px 20px;" class="yinziMobile">
                          {{ item.title }}
                        </div>
                      </div>
                    </slideritem>
                </slider>
              <!-- </div> -->
            </div>
            
            <!-- 第二个轮播图 -->
            <div v-for="(homeItems) in homeList.filter((item,index) => item.themeType === 2 && index === 1)" :key="homeItems.themeId">
              <div class="first" :id="'themeId' + homeItems.themeId" :ref="'themeId' + homeItems.themeId">
                <div class="piece"></div>
                <div class="zxyw">{{ homeItems.themeName }}</div>
                <div class="ckgd goPage" @click="seeMore(homeItems.themeId,homeItems.themeName)">查看更多</div>
              </div>
              <div class="second">
                  <div v-for="homeItem in homeItems.data" :key="homeItem.articleId" @click="goDetail(homeItem.articleId,homeItem.title)">
                      <div class="spot"></div>
                      <div style="font-size: 0.8rem;overflow: hidden; white-space: nowrap;text-overflow: ellipsis">{{ homeItem.title }}</div>
                  </div>
              </div>
              <!-- <div class="section-image">
                <div class="carousel" style="border-radius: 10px 10px;height: 45vw;">
                  <div ref="mobilecarousel__slide" class="carousel__slider">
                    <div class="carousel__slides" :style="{ transform: `translateX(-${slideWidth * currentIndex2}px)` }">
                      <div v-for="(item, index) in carouselList[1].imgList" :key="index" class="carousel__slide" style="height: 45vw;">
                        <img :src="item.image" @click="goDetail(item.id)"/>
                        <div class="yinziMobile">{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel__control carousel__control--prev" @click="prevSlide(1)"></div>
                  <div class="carousel__control carousel__control--next" @click="nextSlide(1)"></div>
                  <div class="carousel__indicators" style="position: absolute;left: 60px;bottom: 11vw;transform: translateX(-50%);-webkit-transform: translateX(-50%);-moz-transform: translateX(-50%);">
                    <span v-for="(item, index) in carouselList[1].imgList" :key="index" :class="{ indicatorsActive: index === currentIndex2 }" class="carousel__indicator" @click="goToSlide(index)"></span>
                  </div>
                  
                </div>
              </div> -->
              <slider ref="slider" :options="options">
                    <slideritem v-for="(item,index) in carouselList[1].imgList" :key="index">
                      <!-- {{item.title}} -->
                      <div style="width:100%;position: relative;height: 50vw;">
                        <img :src="item.image" style="width: 100%;border-radius: 20px;height: 100%;" @click="goDetail(item.id)"/>
                        <div class="carousel__control carousel__control--prev" @click="sliderPrev(1)"></div>
                        <div class="carousel__control carousel__control--next" @click="sliderNext(1)"></div>
                        <div style="position: absolute;bottom: 0;left: 0;width: 100%;height: 45px;line-height: 45px;
                          background-color: rgba(0, 0, 0, 0.4);border-radius:0 0 20px 20px;" class="yinziMobile">
                          {{ item.title }}
                        </div>
                      </div>
                    </slideritem>
              </slider>
            </div>
            <div style="position: relative;">
            </div>
            <!-- 振兴领航 -->
            <div v-for="homeItems in homeList.filter(item => item.themeType === 3)" :key="homeItems.themeId">
              <div class="first" :id="'themeId' + homeItems.themeId" :ref="'themeId' + homeItems.themeId">
                  <div class="piece"></div>
                  <div class="zxyw">{{ homeItems.themeName }}</div>
                  <div class="ckgd goPage" @click="seeMore(homeItems.themeId,homeItems.themeName)">查看更多</div>
              </div>
              <div v-for="item in homeItems.data" :key="item.articleId" @click="goDetail(item.articleId,item.title)">
                <div class="zcsl" style="margin-top: 3.5vw;" v-show="!item.img">
                  <div class="gozxlhText">{{ item.title }}</div>
                  <div class="gozxlhText">{{ item.intro }}</div>
                </div>
                <div class="items gozyxText" style="padding-top: 5px;" v-show="item.img">
                    <div class="items-img">
                        <img :src="item.img">
                    </div>
                    <div class="items-text">
                        <div>{{ item.title }}</div>
                        <div>{{ item.intro }}</div>
                    </div>
                </div>
              </div>
              
            </div>
            <!-- 在一线 -->
            <!-- <div class="first" id="frontLine">
                <div class="piece"></div>
                <div class="zxyw">在一线</div>
                <div class="ckgd goPage">查看更多</div>
            </div>
            <div class="hkt">
                <div class="items gozyxText">
                    <div class="items-img">
                        <img src="https://images.xinfuhuixuan.com/images/xinhuaxinfu/video.png">
                    </div>
                    <div class="items-text">
                        <div>振兴领航：开启乡村振兴新征程</div>
                        <div>日前，在安徽省望江县杨湾镇余埠村的“稻虾轮作”养殖基地，养殖户正在投放小龙虾苗。</div>
                    </div>
                </div>
                <div class="items gozyxText">
                    <div class="items-img">
                        <img src="https://images.xinfuhuixuan.com/images/xinhuaxinfu/953d8a44495d40498e8aa17fff265b4c.jpg">
                    </div>
                    <div class="items-text">
                        <div>振兴领航：开启乡村振兴新征程</div>
                        <div>日前，在安徽省望江县杨湾镇余埠村的“稻虾轮作”养殖基地，养殖户正在投放小龙虾苗。</div>
                    </div>
                </div>
                <div class="items gozyxText">
                    <div class="items-img">
                        <img src="https://images.xinfuhuixuan.com/images/xinhuaxinfu/4b23486cf4954a5b98dff14a2e394b4b.jpg">
                    </div>
                    <div class="items-text">
                        <div>振兴领航：开启乡村振兴新征程</div>
                        <div>日前，在安徽省望江县杨湾镇余埠村的“稻虾轮作”养殖基地，养殖户正在投放小龙虾苗。</div>
                    </div>
                </div>
            </div> -->
            <!-- 关于我们 -->
            <div :id="'themeId' + homeItems.themeId" v-for="homeItems in homeList.filter(item => item.themeType === 4)" :key="homeItems.themeId" :ref="'themeId' + homeItems.themeId">
              <div class="first" id="aboutUs" >
                <div class="piece"></div>
                <div class="zxyw">{{ homeItems.themeName }}</div>
              </div>
              <div class="hkt">
                  <div class="hkt-text">
                      {{ homeItems.data }}            
                  </div>
                  <!-- <div class="hkt-text" style="margin-bottom: 20vw;">
                      新华社作为世界性通讯社，已建成多媒体形态的新闻信息采集发布体系，形成了包括通讯社报道、报刊宣传、音视频制作、网络服务、新媒体发布、移动媒体服务、新闻信息产品营销等多元化业务格局。新华社还积极拓展国际传播渠道，同世界200多个国家和地区的通讯社、报社、电台、电视台等媒体建立了业务合作关系，建成全球卫星采集网和覆盖全球的新闻信息发稿系统，形成了多语种、多媒体、多渠道的国际化传播体系。
                  </div> -->
              </div>
            </div>
      </div>
      <div class="footer" v-if="toTop" @click="goTop">
        <div style="margin-left: 82vw;" id="goTop">
          <img style="height: 13vw;width: 13vw;" src="@/assets/img/Group 2814.png">
        </div>
      </div>
      <div class="bah">
        新华薪福：京ICP备2023028182号-1
      </div>
    </div>
  </div>
  

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { slider, slideritem } from 'vue-concise-slider'
import axios from 'axios'
// axios.defaults.baseURL = 'https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/index/'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    slider,
    slideritem
  },
  data(){
    return{
      // 决定显示pc端还是mobile端
      isSmallScreen: true,
      // 导航栏数据
      navbarList:[],
      homeList:[], // 标题和文章数据
      blockTop:[], // 每个模块距离顶部的距离
      carouselList:[],
      carouselList1:[], // 轮播图数据
      carouselList2:[],
      images: [
        { src: 'https://images.xinfuhuixuan.com/images/xinhuaxinfu/0cc9ab6661984c28b00a6ef52e30c678.jpg' },
        { src: 'https://images.xinfuhuixuan.com/images/xinhuaxinfu/0cc9ab6661984c28b00a6ef52e30c678.jpg' },
        { src: 'https://images.xinfuhuixuan.com/images/xinhuaxinfu/video.png' },
        { src: 'https://images.xinfuhuixuan.com/images/xinhuaxinfu/23602b7a534b4c868218839f10728a5d.jpg' },
      ],
      currentIndex1: 0,
      currentIndex2: 0,
      slideWidth: 0,
      autoPlayInterval1: null,
      autoPlayInterval2: null,
      options: {
        currentPage: 0,//当前页码
        // thresholdDistance: 500,//滑动判定距离
        thresholdTime: 1000,//滑动判定时间
        autoplay:2500,//自动滚动[ms]
        loop:true,//循环滚动
        direction:'horizontal',//方向设置，垂直滚动
        loopedSlides:1,//无限滚动前后遍历数
        slidesToScroll:1,//每次滑动项数
      },
      toTop: false,
      activeItemId:2,
      paramsIndex: ''
    }
  },
  async created(){
    this.paramsIndex = this.$route.params.index
    this.getHomeData()
  },
  mounted() {
    // 决定显示哪个元素
    this.screenWidth()
    window.addEventListener('resize', this.innerWidth)
    this.autoPlay()
    // // 控制滑动顶部按钮是否显示
    window.addEventListener('scroll', this.handleScroll)
    this.getBlockTop()

    // 从别的页面进首页
    if(this.isSmallScreen){
      if(this.$route.params.index){
        const params = this.$route.params
        setTimeout(() => {
          this.scrollToSection(params.elId,params.text,params.id,'click')
        }, 300)
      }
    }else{
      if(this.$route.params.index){
        const params = this.$route.params
        setTimeout(() => {
          // console.log('link' + this.$route.params.index,this.$refs['link' + this.$route.params.index])
          // this.$refs['link' + this.$route.params.index][0].click()
          this.scrollToSection(params.elId,params.text,params.id)
        }, 300)
      }
    }
    
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.checkWidth)
    clearInterval(this.autoPlayInterval1)
    // clearInterval(this.autoPlayInterval2)
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {  
    
  },
  methods:{
    // processedHtml(aaa) {
    //   if(this.isSmallScreen === false){
    //     // 假设我们想要基于某些条件改变样式  
    //     let html = aaa
    //       html = html.replace(/<p/gi, match => {  
    //         // 使用正则表达式匹配 <span> 标签的开头，并添加 style 属性  
    //         // 注意：这种方法可能不总是安全的，因为它假设了 HTML 的格式  
    //         return match.replace(/<p/, '<p style="margin: 0 !important;padding: 0 !important;"')
    //       })
    //       html = html.replace(/<img/gi, match => {  
    //         // 使用正则表达式匹配 <span> 标签的开头，并添加 style 属性  
    //         // 注意：这种方法可能不总是安全的，因为它假设了 HTML 的格式  
    //         return match.replace(/<img/, '<img style="width: 95vw !important;"')
    //       })
    //       html = html.replace(/<video/gi, match => {  
    //         // 使用正则表达式匹配 <span> 标签的开头，并添加 style 属性  
    //         // 注意：这种方法可能不总是安全的，因为它假设了 HTML 的格式  
    //         return match.replace(/<video/, '<video style="width: 100% !important;"')
    //       })
    //       // console.log(html);
    //       // html = html.replace(/<img/, '<img style="width: 95vw !important;"')
    //     return html
    //   }
    // },
    // 物理宽度
    screenWidth(){
      const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
      if(isInWeChatMiniProgram){
        this.isSmallScreen = false
        return
      }
      let width = screen.width
      if (width >= 550) {
        this.isSmallScreen = true
        setTimeout(() => {
          const element = this.$refs.carousel__slide
          if(element){
            this.slideWidth = element.offsetWidth
          }
        }, 300)
      } else {
        this.isSmallScreen = false
        this.$nextTick(() => {
          // const element = this.$refs.mobilecarousel__slide
          // this.slideWidth = element.offsetWidth
          this.slideWidth = window.innerWidth / 100 * 95
        })
      }
      
    },
    // 窗口宽度
    innerWidth(){
      const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
      if(isInWeChatMiniProgram){
        this.isSmallScreen = false
        return
      }
      let width = window.innerWidth
      if (width >= 550) {
        this.isSmallScreen = true
        this.$nextTick(() => {
          const element = this.$refs.carousel__slide
          if(element){
            this.slideWidth = element.offsetWidth
          }
          // this.slideWidth = window.innerWidth / 100 * 95
        })
      } else {
        this.isSmallScreen = false
        this.$nextTick(() => {
          // const element = this.$refs.mobilecarousel__slide
          this.slideWidth = window.innerWidth / 100 * 95
        })
      }
    },
    // 获取首页数据
    async getHomeData(){
      const res = await axios.get('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/index/getTheme')
      if(res.data.code === 200){
        const data = res.data.extend.data
        data.forEach((item,index) => {
          if(item.themeType !== 1 && item.themeType !== 5){
            this.navbarList.push(item)
          }
        })
        this.navbarList.forEach((item,index) => {
          if(index === 0 && !this.$route.params.index){
            item.visible = true
          }else{
            item.visible = false
          }
        })
      }

      // console.log(this.navbarList,'导航栏')
      const res2 = await axios.get('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/index/getArticleTitle')
      if(res2.data.code === 200){
        const data2 = res2.data.extend.data
        let homeDataList = []
        data2.forEach((item,index) => {
          if(item.themeType !== 1){
            homeDataList.push(item)
          }
        })
        homeDataList.forEach((item,index) => {
          if(item.data == null){
            item.data = []
          }
          if(Array.isArray(item.data)){
            let arr = []
            item.data.forEach((item2,index2) => {
              if(index2 < 5){
                arr.push(item2)
              }
            })
            homeDataList[index].data = arr
          }
        })
        this.homeList = homeDataList
        // this.carouselList = []
        data2.forEach((item,index) => {
          if(item.themeType == 5){
            this.carouselList.push(item)
          }
        })
        // console.log(this.carouselList[0].imgList,'imgList')
      }
      
      // console.log(homeDataList)
    },
    // 跳转广告页
    async advertisement(id){
      let articleId = ''
      const res2 = await axios.get('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/index/getArticleTitle')
      if(res2.data.code === 200){
        const data = res2.data.extend.data
        data.forEach(item => {
          if(item.themeType == 1){
            articleId = item.data[0].articleId
          }
        })
      }
      this.$router.push({
        path: '/advertisement',
        query:{
          articleId: articleId
        }
      })
    },
    // 滚动效果
    scrollToSection(elId,text,id, event) {
      // this.activeItemId = id
      // console.log(this.navbarList)
      
      // console.log(elId,text,id === this.navbarList[1].id)
      // 不能直接改变原数组，否则会很慢
      if(event === 'click'){
        const arr = this.navbarList.slice()
        arr.forEach(item => {
          if(item.id === id){
            item.visible = true
          }else{
            item.visible = false
          }
        })
        this.navbarList = arr
      }
      const section = document.getElementById(elId)
      // console.log(section,elId)
      if (section) {  
        // 使用 window.scrollTo 方法实现平滑滚动  
        // 第一个参数是 x 坐标，第二个参数是 y 坐标  
        // behavior: 'smooth' 表示平滑滚动
        if(text === 'pc'){
          window.scrollTo({  
            top: section.offsetTop,  
            behavior: 'smooth'  
          })
        }else{
          window.scrollTo({  
            top: section.offsetTop - 105,  
            behavior: 'smooth'  
          })
        }
        }
    },
    // 轮播图
    nextSlide(index) {
      if(index === 0){
        if (this.currentIndex1 < this.carouselList[0].imgList.length - 1) {
          this.currentIndex1++
        } else {
          this.currentIndex1 = 0
        }
      }else{
        if (this.currentIndex2 < this.carouselList[1].imgList.length - 1) {
          this.currentIndex2++
        } else {
          this.currentIndex2 = 0
        }
      }
    },
    prevSlide(index) {
      if(index === 0){
        if (this.currentIndex1 > 0) {
          this.currentIndex1--
        } else {
          this.currentIndex1 = this.carouselList[0].imgList.length - 1
        }
      }else{
        if (this.currentIndex2 > 0) {
          this.currentIndex2--
        } else {
          this.currentIndex2 = this.carouselList[1].imgList.length - 1
        }
      }
    },
    goToSlide(index) {
      if(index === 0){
        this.currentIndex1 = index
      }else{
        this.currentIndex2 = index
      }
    },
    autoPlay() {
      this.autoPlayInterval1 = setInterval(() => {
        this.nextSlide(0)
        this.nextSlide(1)
      }, 3000) // 每3秒切换一次
      // this.autoPlayInterval2 = setInterval(() => {
        
      // }, 3000) // 每3秒切换一次
    },
    // slide (data){
    //   console.log(data)
    // },
    // onTap (data){
    //   console.log(data)
    // },
    // onInit (data){
    //   console.log(data)
    // },
    // 移动端下一页
    sliderNext (index) {
      this.$nextTick(() => {
        this.$refs.slider[index].$emit('slideNext')
      })
      
    },
    sliderPrev (index) {
      this.$nextTick(() => {
        this.$refs.slider[index].$emit('slidePre')
      })
      
    },
    // 查看更多
    seeMore(themeId,text){
      this.$router.push({
        path:'/article',
        query:{
          title: text,
          themeId: themeId
        }
      })
    },
    // 查看文章详情
    goDetail(articleId,title){
      this.$router.push({
        path:'/articleDetail',
        query:{
          articleId: articleId,
          title: title
        }
      })
    },
    // 获取移动端每个模块距离顶部的距离
    getBlockTop(){
      if(this.isSmallScreen === false){
        setTimeout(() => {
          this.homeList.forEach(item => {
            if(item.themeType !== 5){
              const a = 'themeId' + item.themeId
              this.blockTop.push(this.$refs[a][0].offsetTop)
            }
          })
        }, 300)
        // this.$nextTick(() => {
        //   this.homeList.forEach(item => {
        //     if(item.themeType !== 5){
        //       const a = 'themeId' + item.themeId
        //       console.log(this.$refs[a],'refs')
        //       this.blockTop.push(this.$refs[a][0].offsetTop)
        //     }
        //   })
        // })
      }
    },
    handleScroll() {
      // 控制返回顶部按钮显示/隐藏
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const screenHeight = window.innerHeight || document.documentElement.clientHeight
      if (scrollTop > screenHeight) {  
        this.toTop = true
      } else {  
        this.toTop = false
      }
      // 屏幕滚动到响应的模块，navbar高亮
      // if(this.isSmallScreen === false && !this.$route.params.index){
      if(this.isSmallScreen === false){
        const arr = this.navbarList.slice()
        arr.forEach((item,index) => {
          if(scrollTop + 110 > this.blockTop[index] && scrollTop + 110 < this.blockTop[index + 1]){
            item.visible = true
            let scrollableDiv = this.$refs.mobileUl
            if(index > 3){
              // 向左滚动
              const scrollWidth = scrollableDiv.clientWidth
              // 计算向左滚动75%的距离  
              const scrollLeftValue = scrollWidth * 0.75
              // 设置scrollLeft的值实现滚动
              this.$nextTick(() => {
                scrollableDiv.scrollLeft = scrollLeftValue
              })
            }else{
              // 向右滚动
              const scrollWidth = scrollableDiv.clientWidth
              const scrollRightValue = scrollWidth * 0.75
              this.$nextTick(() => {
                scrollableDiv.scrollLeft = -scrollRightValue
              })
            }
          }else{
            item.visible = false
          }
          // 如果滚到最后一个并且超过显示高度设置为true
          if(scrollTop + 110 > this.blockTop[this.blockTop.length - 1] && index === this.blockTop.length - 1){
            item.visible = true
          }
        })
        this.navbarList = arr
      }
      // else{
      //   console.log(this.$route.params.index)
      //   const arr = this.navbarList.slice()
      //   arr.forEach((item,index) => {
      //     if(item.id === this.$route.params.index + 1){
      //       item.visible = true
      //     }
      //   })
      //   this.navbarList = arr
      // }
    },
    // 滚动到顶部
    goTop(){
      window.scrollTo({  
        top: 0,  
        behavior: 'smooth'  
      })
    },
  }
}

</script>

<style lang="less" scoped>
// pc
body, html {margin: 0 !important;padding: 0 !important;font-family: Arial, sans-serif;font-size:32px;}
*{  margin: 0; padding: 0; border: none;  }
html {  
    scroll-padding-top: 110px !important; /* 导航栏的高度 */  
}
header {height: 11vw;}
header img{width: 100%; height: 100%;}
.navbar {display: flex;justify-content: space-around;background-color:#005099;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); width: 80%;padding: 0 10%;}
.a_title{
  width:14.2%;
  height:50px;
  // a{
  //   font-size: 14px;
  // }
}

.navbar a {font-size:18px;width:100%;height:90%;color: #fff;text-decoration: none;position: relative;display: flex;justify-content: center;align-items: center;}
/* .navbar a::after {content: "";position: absolute;left: 15%; bottom: 0;width: 0;height: 2px;background-color: red;transition: width 0.5s ease; } */
/* .navbar a:hover {color:#50B0E4;} */
/* .navbar a:hover::after {width: 70%; } */
.line{height:2px;width:80px;background-color:#fff;margin-left: calc(50% - 40px);display: none;}
.main-content {padding: 20px 12%;}
.section {margin-bottom: 40px;}
.section-title {font-size: 32px;margin-bottom: 10px;margin-right: auto;display: flex;align-items: center;}
.shut{background-color: #046AC8 ; width: 5px; height: 36px;margin-right: 10px;}
.more-link {margin-right: 10px; padding-right: 15px;font-size:16px;color:#666666;font-weight: bold;text-decoration: none;background-image: url(@/assets/img/ckgd.png);background-size: 10px;background-repeat: no-repeat;background-position: center right;cursor: pointer;}
.banner {max-width: 100%;height: auto;}
.section-header {display: flex;justify-content: space-between;align-items: center;}
li{padding:0.5em;}
.right{width:99%;overflow: hidden; white-space: nowrap;text-overflow: ellipsis;font-size: 22px;color: #333;}
.right:hover{color: #FB5733 !important;cursor: pointer;}
@media (min-width: 480px) {
.section {display: flex;flex-wrap:wrap;align-items: flex-end;}
.section-content {flex:1;width:100%;}
.section-image {flex:1;width:100%;padding-left: 5%;position: relative;}
.yuandian{width: 0.5em;height: 0.5em;background-color: black;border-radius: 50%;}
.miaoshu{text-align: left;font-size: 20px; color: #999;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;text-indent: 2em;margin-top: 16px;margin-bottom: 4px;}
.example{width: 100%; height: 240px;font-size: 40px;text-align: center;margin: 20px auto;background-color: #464576;position: relative;}
.carousel-item{line-height: 336px;color: #fff;font-family:  Arial Black}
ul,ol,li,div {margin: 0;padding: 0;}
ul{list-style: none;}
.conttitle{list-style:disc;padding-left: 30px;height: 240px;}
.conttitle li {color: #4C9FEB;list-style:disc;text-align: left;}	
.ft-carousel {position: relative;width: 100%;height: 100%;overflow: hidden;}
.ft-carousel .carousel-inner {position: absolute;left: 0;top: 0;height: 100%;}
.ft-carousel .carousel-inner .carousel-item {float: left;height: 100%;}
.ft-carousel .carousel-item img {width: 100%;height: 100%;}
.ft-carousel .carousel-indicators {position: absolute;left: 0;bottom: 50px;text-align: center;font-size: 0;width: 30%;}
.ft-carousel .carousel-indicators span {display: inline-block;width: 7px;height:7px;background-color: #fff;margin: 0 4px;border-radius: 50%;cursor: pointer;}
.ft-carousel .carousel-indicators span.active {background-color: #de3a3a;}
.ft-carousel .carousel-btn {position: absolute;top: 50%;width: 50px;height: 45px;margin-top: -25px;cursor: pointer;}	
.ft-carousel .carousel-prev-btn {left: 0;background: url(@/assets/img/Group\ 2681.png) no-repeat; background-position: right;}
.ft-carousel .carousel-next-btn {right: 0;background: url(@/assets/img/Group\ 2682.png) no-repeat;}
.wentitle{font-weight: 600;font-size: 22px;cursor: pointer;text-align: left;}
.wentitle:hover{color: #FB5733;}
.rightbor{border-right: 2px solid #AFB2B5;width: 100%;}
.yinzi{position: absolute; left: 0; bottom: 0;height: 40px;line-height: 40px;color: #fff;font-size: 16px;background-color: rgba(0, 0, 0, 0.4);width: 100%;text-align: left;padding-left: 10%;}
.bottombe{height: 70px;background-color: #005099;font-size: 18px;color: #FFFFFF;line-height: 70px;text-align: center;}
}

// 轮播图样式
.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel__slider {
  width: 100%;
}

.carousel__slides {
  display: flex;
  transition: transform 500ms ease-in-out;
}

.carousel__slide {
  position: relative;
  width: 100%;
  height: 240px;
  flex-shrink: 0;
}

.carousel__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel__control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 16px;
  width: 32px;
  height: 32px;
  color: white;
  cursor: pointer;
  z-index: 2;
}

.carousel__control--prev {
  left: 20px;
  background: url(@/assets/img/Group\ 2681.png) no-repeat 50% 50%; 
}

.carousel__control--next {
  background: url(@/assets/img/Group\ 2682.png) no-repeat 50% 50%;
  right: 20px;
}

.carousel__indicatorsPc {
  position: absolute;
  top: 170px;
  left: 20px;
  display: flex;
  justify-content: center;
  // margin-top: 10px;
  z-index: 2;
}

.carousel__indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 5px;
  cursor: pointer;
}

.carousel__indicator.active {
  width: 20px;
  border-radius: 10px 10px;
}

// mobile
.top{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 70px;
    z-index: 9;
}
.mobileNavbar {  
    position: fixed;
    top: 70px;
    overflow-x:scroll;
    scrollbar-width: none; /* Firefox 浏览器 */  
    -ms-overflow-style: none; /* IE 和 Edge 浏览器 */  
    /* &::-webkit-scrollbar {  
        display: none; 
    } */
    white-space: nowrap;
    width: 100vw;
    height: 50px;
    background-image: url('@/assets/img/Rectangle\ 1092.png'); 
    z-index: 9;
  }  
  .mobileNavbar ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none ;
    height: 50px;
  }
  .mobileNavbar ul li {  
    padding: 0;
    flex-shrink: 0;
    width: 25vw; 
    height: 100%;
    box-sizing: border-box;
  } 
  .mobileNavbar li a {  
    display: block;  
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    /* padding: 14px 0;   */
    text-decoration: none;
    font-size: 0.8rem;
    line-height: 50px;
  } 
  li{
    .active{
      color: #fff !important;
      font-size: 0.95rem !important;
      font-weight: bold !important;
      background: url('@/assets/img/Rectangle\ 1183.png') no-repeat !important;
      background-position: center 80% !important;
      background-size: 13vw 1px !important;
    }
  }
  #link6{
    margin-left: -3vw;
  }
  #link6.active{
    margin-left: -5.2vw;
  }
  .main{
    margin: 105px auto 20vw;
    width: 95vw;
  }
  .first {
    display: flex;
    padding-top: 6vw;
  }
  .piece{
     /* display: ; */
     background-color: #046AC8;
     width: 1vw;
     height: 7vw;
  }
  .zxyw{
    padding-left: 6px;
    width:60vw;
    text-align: left;
    line-height: 7vw;
    font-size: 1rem;
    font-weight: bold;
    color: #333333;
    
  }
  .ckgd{
    padding-right: 5vw;
    width: 39vw;
    line-height:7vw;
    text-align: right;
    color: #666666;
    background: url('@/assets/img/ckgd.png') no-repeat;
    background-position: 95% center;
    background-size: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }
  .second{
    margin-top: 3vw;
    margin-bottom: 5vw;
    /* height: 50vw; */
    /* background-color: green; */
    color: #333;
  }
  .second > div{
    display: flex;
    width: 100%;
    height: 7vw;
    line-height: 7vw;
    /* border: 1px solid red; */
    box-sizing: border-box;
  }
  .spot {
    margin-top: 2.9vw;
    margin-right: 1vw;
    width: 1.2vw;
    height: 1.4vw;
    background-color: #4C9FEB;
    border-radius: 0.6vw;
  }

/* 政策速览 */
.zcsl > div:nth-child(2n - 1){
    height: 7.5vw;
    line-height: 7.5vw;
    font-size: 0.85rem;
    font-weight: bold;
    color: #333333;
}
.zcsl > div:nth-child(2n){
    padding-bottom: 5px;
    font-size: 0.7rem;
    color: #666666;
    border-bottom: 1px solid #BDC0C3;
}

/* 会客厅 */
.hkt{
    margin-top: 5vw;
}
.items{
    display: flex;
    margin-bottom: 1.5vw;
}
.items-img img{
    width: 35vw;
    height: 20vw;
    border-radius: 6px;
}
.items-text{
    margin-left: 2vw;
    width: 58vw;
    height: 20vw;
    border-bottom: 1px solid #BDC0C3;
}
.items-text > div:nth-child(2n - 1){
    height: 7.5vw;
    line-height: 7.5vw;
    font-size: 0.85rem;
    text-align: left;
    font-weight: bold;
    color: #333333;
    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
}
.items-text > div:nth-child(2n){
    padding-bottom: 5px;
    font-size: 0.7rem;
    height: 12.5vw;
    line-height: 12.5vw;
    text-align: left;
    color: #666666;
    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
}
.hkt-text{
  text-align: left;
    text-indent:2.25rem;
    font-size: 0.75rem;
    color: #666666; 
}


.footer{
    position: fixed;
    bottom: 17vw;
}

/* 备案号 */
.bah{
  position: fixed;
  z-index: 2;
  bottom: 0;
    width: 100vw;
    height: 15vw;
    line-height: 15vw;
    text-align: center;
    background-color: #005099;
    font-size: 0.8rem;
    color: #FFFFFF;
}


.yinziMobile{
  position: absolute;
  bottom: 0;
  height: 10vw;
  line-height: 10vw;
  font-size: 0.9rem;
  width: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4)
}
.indicatorsActive{
  display: inline-block;
  background-color: #fff;
  width: 16px;
  height: 8px;
  border-radius: 10px 10px;
}
.sdlider-img {
    width: 100%;
    height: 45vw;
    border-radius: 5px;
}
.slider-tle{
    position: absolute;
    bottom: 1vw;
    width: 100%;
    height: 10vw;
    line-height: 10vw;
    border-bottom-left-radius: 5px;  
    border-bottom-right-radius: 5px;
    font-size: 0.9rem;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.5)
}
.gozxlhText{
  text-align: left;
  overflow: hidden; 
  white-space: nowrap;
  text-overflow: ellipsis;
}
.goznxnText{
  text-align: left;
}

// 移动端轮播图样式
::v-deep .slider-pagination-bullets{
  position: absolute;
  left: 0;
  bottom: 53px !important;
  margin-left: 30px;
  text-align: left;
  .slider-pagination-bullet{
    background-color: #fff !important;
    opacity: 1;
  }
  .slider-pagination-bullet-active{
    display: inline-block;
    width: 16px;
    height: 8px;
    border-radius: 4px;
  }
}
</style>