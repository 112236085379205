import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
 
Vue.use(ElementUI)

Vue.config.productionTip = false
// axios.defaults.baseURL = 'https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/index/'
// 在请求头中添加token   请求拦截器
// axios.interceptors.request.use((config) => {
//   config.headers = {
//     'Content-type': 'application/x-www-form-urlencoded'
//   }
//   return config
// }, (error) => {
//   // 处理请求错误
//   return Promise.reject(error)
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
