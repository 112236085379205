<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default{
  data(){
    return{

    }
  },
  created(){
    // 捕获重复路由错误
    // this.$router.push({path:'/home'}).catch(() => {})
  },
  mounted() {  
    
  },
  methods:{

  }
}
</script>

<style lang="less">
body{
  margin: 0;
  padding: 0;
  border: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
